import {createElement, Fragment, useEffect, useMemo, useState} from "react";
import {
	Box,
	Button,
	Card,
	CardActions,
	Chip, Dialog, DialogTitle,
	Divider,
	Grid, IconButton,
	List,
	ListItem, ListItemSecondaryAction,
	ListItemText, MenuItem,
	Typography
} from "@mui/material";
import {DeleteOutline, EditOutlined} from "@mui/icons-material";
import {useAsync} from "react-async-hook";
import {ConfirmDialog, EditForm, TextField, useFlag} from "@variocube/app-ui";
import {useLocalization} from "../../i18n";
import {CustomFieldInput, Item, Rental, RentalCustomFieldRequest} from "../../data/types";
import {CustomField, customFieldApi} from "../../data/custom-fields";
import {LabeledData} from "../../components/LabeledData";
import {tenantUserStore} from "../../store/TenantUserStore";

interface RentalCustomFieldListProps {
	rental: Rental;
	item: Item;
	onAdd: (request: RentalCustomFieldRequest) => Promise<void>;
	onUpdate: (request: RentalCustomFieldRequest) => Promise<void>;
	onDelete: (slug: string) => Promise<void>;
}

export function RentalCustomFieldList({rental, item, onAdd, onUpdate, onDelete}: RentalCustomFieldListProps) {
	const {t} = useLocalization();

	const {result: fields = []} = useAsync(async () => {
		if (rental.tenantId && item.category) {
			return customFieldApi.listAll(rental.tenantId, item.category.uuid);
		}
		return [];
	}, [rental.tenantId, item.category]);

	const [addMode, setAddMode, clearAddMode] = useFlag(false);

	const [field, setField] = useState<CustomFieldInput>();
	const [deleteSlug, setDeleteSlug] = useState<string|null>(null);

	async function handleAdd(request: RentalCustomFieldRequest) {
		await onAdd(request);
		clearAddMode();
	}

	async function handleUpdate(request: RentalCustomFieldRequest) {
		await onUpdate(request);
		setField(undefined);
	}

	async function handleDelete() {
		if (deleteSlug) {
			await onDelete(deleteSlug);
			setDeleteSlug(null);
		}
	}

	const availableFields = useMemo(() =>
		fields
			.filter(f => !rental.customFieldInputs.find(i => i.slug === f.slug))
		, [rental.customFieldInputs, fields])

	return (
		<Fragment>
			<Card>
				<List disablePadding>
					{rental.customFieldInputs.map((input, i) => (
						<Fragment>
							{i !== 0 && <Divider />}
							<ListItem key={input.slug}>
								<ListItemText
									primary={(
										<Typography variant="overline" color="textSecondary">{input.name} <Chip size="small" label={input.type} /></Typography>
									)}
									secondary={(
										<Typography>{input.value ?? '-'}</Typography>
									)}
								/>
								<ListItemSecondaryAction>
									<IconButton onClick={() => setField(input)}><EditOutlined /></IconButton>
									<IconButton color="error" onClick={() => setDeleteSlug(input.slug)}><DeleteOutline /></IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						</Fragment>
					))}
				</List>
				{tenantUserStore.admin && (
					<CardActions>
						<Button variant="outlined" onClick={setAddMode}>{t('actions.add')}</Button>
					</CardActions>
				)}
			</Card>

			<Dialog open={addMode} onClose={clearAddMode} fullWidth maxWidth="sm">
				<DialogTitle>{t('rentals.addFields.title')}</DialogTitle>
				<CustomFieldForm
					onSave={handleAdd}
					onCancel={clearAddMode}
					fields={availableFields}
				/>
			</Dialog>

			<Dialog open={!!field} onClose={() => setField(undefined)} fullWidth maxWidth="sm">
				<DialogTitle>{t('actions.edit')}</DialogTitle>
				<CustomFieldForm
					onSave={handleUpdate}
					onCancel={() => setField(undefined)}
					fields={availableFields}
					field={field}
				/>
			</Dialog>

			<ConfirmDialog
				open={deleteSlug !== null}
				onClose={() => setDeleteSlug(null)}
				title={t("rentals.customFields.delete.title")}
				cancel={t("actions.cancel")}
				onConfirm={handleDelete}
			>
				{t("rentals.customFields.delete.message")}
			</ConfirmDialog>
		</Fragment>
	)
}

interface CustomFieldFormProps {
	onSave: (data: RentalCustomFieldRequest) => Promise<void>;
	onCancel: () => void;
	fields: CustomField[];
	field?: CustomFieldInput;
}

function CustomFieldForm({onSave, onCancel, fields, field}: CustomFieldFormProps) {
	const {t, s} = useLocalization();

	const [slug, setSlug] = useState<string|null>();
	const [value, setValue] = useState("");

	useEffect(() => {
		if (field) {
			setSlug(field.slug);
			setValue(field.value);
		}
	}, [field]);

	async function handleSave() {
		if (slug && value) {
			await onSave({ slug, value });
		}
	}

	return (
		<EditForm
			loading={false}
			onSave={handleSave}
			onCancel={onCancel}
			labels={s("actions")}
		>
			<Box p={2}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						{!field && (
							<TextField
								fullWidth select required
								label={t("common.type")}
								value={slug}
								onChange={setSlug}
							>
								{fields.map(f => (
									<MenuItem key={f.slug} value={f.slug}>{f.name}</MenuItem>
								))}
							</TextField>
						)}
						{field && (
							<LabeledData label={t("common.field")}>
								{field.name}
							</LabeledData>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth required
							label={t("common.value")}
							value={value}
							onChange={setValue}
						/>
					</Grid>
				</Grid>
			</Box>
		</EditForm>
	)
}
