import {useAsync, useAsyncCallback} from "react-async-hook";
import {Alert, Box, Button, Chip, DialogActions, DialogContent, Grid, LinearProgress, Link} from "@mui/material";
import {createElement, FormEvent, Fragment, PropsWithChildren, useEffect, useMemo, useState} from "react";
import {columnSettingsLabels, useLocalization} from "../../i18n";
import {
	ConfirmButton,
	DataTable, DataTableCellProps,
	DataTableColumn,
	DataTableColumnSettings,
	DataTableHeader, RadioGroup,
	TextField,
	useDataTableColumnStorage,
	useDataTableStorage, useFlag,
	useSpringPage,
	useSpringPageable
} from "@variocube/app-ui";
import {CustomFieldRequest, CustomField, customFieldApi, CustomFieldType} from "../../data/custom-fields";
import {BooleanDisplay} from "../../components/BooleanDisplay";
import {tenantUserStore} from "../../store/TenantUserStore";
import {AddFab} from "../../components/AddFab";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import {CategorySelection} from "../../components/category-selection";
import {Link as RouterLink} from "react-router-dom";


export function CustomFieldsSettings() {
	const {t} = useLocalization();

	const [createModal, setCreateModal, clearCreateModal] = useFlag(false);
	const [activeField, setActiveField] = useState<CustomField>();

	const available = useMemo<DataTableColumn<CustomField>[]>(() => [
		{
			field: 'name',
			sortable: true,
			label: t('common.name'),
			default: true,
			component: ({row}) => (
				<FieldColumn onClick={() => setActiveField(row)}>
					{row.name}
				</FieldColumn>
			)
		},
		{
			field: 'type',
			sortable: true,
			label: t('common.type'),
			default: true,
			component: ({row}) => (
				<FieldColumn onClick={() => setActiveField(row)}>
					{row.type}
				</FieldColumn>
			)
		},
		{
			field: 'slug',
			sortable: true,
			label: "Slug",
			default: true,
			component: ({row}) => (
				<FieldColumn onClick={() => setActiveField(row)}>
					<Chip label={row.slug} />
				</FieldColumn>
			)
		},
		{
			field: 'required',
			sortable: true,
			label: t('common.required'),
			default: true,
			component: ({row}) => (
				<FieldColumn onClick={() => setActiveField(row)}>
					<BooleanDisplay value={row.required} />
				</FieldColumn>
			)
		},
		{
			field: 'availableOnReturn',
			sortable: true,
			label: t('customFields.availableOnReturn'),
			default: true,
			component: ({row}) => (
				<FieldColumn onClick={() => setActiveField(row)}>
					<BooleanDisplay value={row.availableOnReturn} />
				</FieldColumn>
			)
		},
		{
			field: 'category.name',
			sortable: true,
			label: t('categories.singular'),
			default: true,
			component: FieldCategory
		}
	], [t]);

	const {columns, setColumns} = useDataTableColumnStorage("CustomFieldListTableColumns", available);
	const storage = useDataTableStorage("CustomFieldList", { pageSize: 25 });
	const pageable = useSpringPageable(storage);

	const {result, loading, error, execute: reload} = useAsync(() => customFieldApi.list(tenantUserStore.getTenantId(), pageable), [pageable]);
	const {rows, page} = useSpringPage(result);

	function handleReload() {
		reload()
			.catch(console.error);
		clearCreateModal();
		setActiveField(undefined);
	}

	return (
		<Fragment>
			<DataTable
				header={
					<DataTableHeader>
						<DataTableColumnSettings
							columns={available}
							selected={columns}
							onChange={setColumns}
							labels={columnSettingsLabels()}
						/>
					</DataTableHeader>
				}
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				pageSizes={[25, 50, 100]}
				{...storage}
			/>
			{loading && <LinearProgress />}

			{tenantUserStore.staff && <AddFab onClick={setCreateModal} />}

			<FieldDialog
				open={createModal}
				onCancel={clearCreateModal}
				onSubmit={handleReload}
			/>

			<FieldDialog
				open={activeField !== undefined}
				onCancel={() => setActiveField(undefined)}
				onSubmit={handleReload}
				field={activeField}
			/>
		</Fragment>
	)
}

function FieldColumn({children, onClick}: PropsWithChildren<{ onClick: () => void }>) {
	return (
		<Link
			onClick={onClick}
			sx={{
				cursor: 'pointer',
			}}
		>
			{children}
		</Link>
	)
}

function FieldCategory({row}: DataTableCellProps<CustomField>) {
	return row.categoryUuid
		? (
			<Link component={RouterLink} to={`/categories/${row.categoryUuid}`}>
				{row.categoryName}
			</Link>
		)
		: <span></span>
}

interface FieldDialogProps {
	open: boolean;
	onCancel: () => void;
	onSubmit: () => void;
	field?: CustomField;
}

function FieldDialog({open, onCancel, onSubmit, field}: FieldDialogProps) {
	const {t} = useLocalization();

	const [categoryUuid, setCategoryUuid] = useState('');
	const [name, setName] = useState('');
	const [slug, setSlug] = useState('');
	const [type, setType] = useState(CustomFieldType.STRING);
	const [required, setRequired] = useState<'0'|'1'>('0');
	const [availableOnReturn, setAvailableOnReturn] = useState<'0'|'1'>('0');

	useEffect(() => {
		setCategoryUuid(field?.categoryUuid ?? '');
		setName(field?.name ?? '');
		setSlug(field?.slug ?? '');
		setType(field?.type ?? CustomFieldType.STRING);
		setRequired(field?.required ? '1' : '0');
		setAvailableOnReturn(field?.availableOnReturn ? '1' : '0');
	}, [field]);

	const {error: createError, loading: createLoading, execute: createCustomField} = useAsyncCallback(customFieldApi.create);
	const {error: updateError, loading: updateLoading, execute: updateCustomField} = useAsyncCallback(customFieldApi.update);
	const {loading: deleteLoading, execute: deleteCustomField} = useAsyncCallback(customFieldApi.del)

	function handleFormSubmit(ev: FormEvent) {
		ev.preventDefault();

		const centerId = tenantUserStore.getTenantId();
		const request: CustomFieldRequest = {
			name,
			slug,
			type,
			required: required === '1',
			availableOnReturn: availableOnReturn === '1',
			categoryUuid,
		};

		if (field !== undefined) {
			updateCustomField(centerId, field.id, request)
				.then(handleSubmit)
				.catch(console.error);
		} else {
			createCustomField(centerId, request)
				.then(handleSubmit)
				.catch(console.error);
		}
	}

	async function handleDelete() {
		if (field !== undefined) {
			await deleteCustomField(tenantUserStore.getTenantId(), field.id);
			handleSubmit();
		}
	}

	function handleSubmit() {
		onSubmit();
		setCategoryUuid('');
		setName('');
		setSlug('');
		setType(CustomFieldType.STRING);
		setRequired('0');
		setAvailableOnReturn('0');
	}

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="sm"
			component="form"
			onSubmit={handleFormSubmit}
		>
			<DialogTitle>{field !== undefined ? t('actions.edit') : t('actions.create')}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} sx={{ pt: 1 }}>
					<Grid item xs={12}>
						<CategorySelection
							value={categoryUuid}
							onChange={setCategoryUuid}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth required
							label={t('common.name')}
							value={name}
							onChange={setName}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth required
							label="Slug"
							value={slug}
							onChange={setSlug}
						/>
					</Grid>
					<Grid item xs={12}>
						<RadioGroup
							label={t('common.type')}
							value={type}
							onChange={t => setType(t as CustomFieldType)}
							row={true}
							options={Object.keys(CustomFieldType)}
						/>
					</Grid>
					<Grid item xs={12}>
						<RadioGroup
							label={t('common.required')}
							value={required}
							onChange={r => setRequired(r)}
							row={true}
							options={['0', '1']}
							renderLabel={o => o === '1' ? t('yes') : t('no')}
						/>
					</Grid>
					<Grid item xs={12}>
						<RadioGroup
							label={t('customFields.availableOnReturn')}
							value={availableOnReturn}
							onChange={r => setAvailableOnReturn(r)}
							row={true}
							options={['0', '1']}
							renderLabel={o => o === '1' ? t('yes') : t('no')}
						/>
					</Grid>
				</Grid>
				{(createError || updateError) && (
					<Alert severity="error">{(createError ?? updateError)?.message ?? 'Error'}</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<ConfirmButton
					title={t('actions.delete')}
					cancel={t('actions.cancel')}
					onConfirm={handleDelete}
					disabled={deleteLoading}
					variant="outlined"
					color="error"
				>
					Are you sure?
				</ConfirmButton>
				<Box flexGrow={1} />
				<Button type="submit" variant="contained" disabled={createLoading ?? updateLoading}>{field !== undefined ? t('actions.save') : t('actions.create')}</Button>
				<Button onClick={onCancel} disabled={createLoading ?? updateLoading}>{t('actions.cancel')}</Button>
			</DialogActions>
		</Dialog>
	)
}

